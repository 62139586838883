import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Intro() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box
        sx={{
          height: "100vh",
          backgroundColor: "#111",
          backgroundImage: "url('bg-pattern.svg')",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 2,
        }}
      >
        <Typography
          variant={isSmallScreen ? "h4" : "h2"}
          color="#fff"
          sx={{ fontWeight: 600, textShadow: "2px 2px 2px #222" }}
          gutterBottom
        >
          Corrie Stoddard
        </Typography>
        <Typography
          variant={isSmallScreen ? "h6" : "h4"}
          color="#eee"
          sx={{
            fontWeight: 400,
            textShadow: "1px 1px 1px #222",
            textAlign: "center",
          }}
        >
          Front end focused full stack engineer passionate about UI/UX.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ marginTop: 3 }}
          href="#portfolio"
        >
          <Typography variant="button"> View My Work </Typography>
        </Button>
      </Box>
    </>
  );
}
