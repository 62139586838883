import "./App.css";
import Home from "./Pages/Home";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { components } from "Theme/components";
import { palette } from "Theme/palette";
import { typography } from "Theme/typography";

const theme = createTheme({
  components,
  palette,
  typography,
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Home />
    </ThemeProvider>
  );
}

export default App;
