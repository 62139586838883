import Box from "@mui/material/Box";
import NavBar from "./Navbar";
import Intro from "./Intro";
import Experience from "./Experience";
import Portfolio from "./Portfolio";
import Contact from "./Contact";
import About from "./About";
import { Divider } from "@mui/material";

export default function Home() {
  return (
    <Box>
      <Intro />
      <Divider />
      <About />
      <Divider />
      <Experience />
      <Divider />
      <Portfolio />
      <Divider />
      <Contact />
    </Box>
  );
}
