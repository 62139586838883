import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { CSSTransition } from "react-transition-group";
import { useInView } from "react-intersection-observer";
import "../Styles/about.css";

function Summary() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width={{ xs: "90vw", sm: "80vw", md: "50vw" }}
      marginLeft={{ xs: 0, md: "3vw" }}
      mt={{ xs: 3, md: 0 }}
      textAlign={{ xs: "center", md: "left" }}
    >
      <Typography paragraph variant="body1" gutterBottom>
        As a dedicated full-stack/ front-end software engineer, I have
        cultivated over 4 years of experience in designing and implementing
        innovative and user-friendly interfaces. My professional journey has
        been driven by a passion for creating seamless user experiences that
        align with client visions and end-user needs.
      </Typography>
      <Typography variant="body1">
        Beyond the lines of code and intricate frameworks, I have a fervor for
        diving deep into the realms of RPGs and strategy games during my free
        time. When I'm not engrossed in the digital world or crafting the next
        UI component, you can often find me spending quality time with my two
        feline companions, whose playful antics serve as a constant reminder of
        the simple joys in life.
      </Typography>
    </Box>
  );
}

export default function About() {
  const { ref, inView } = useInView({ triggerOnce: true });

  return (
    <div ref={ref}>
      <CSSTransition in={inView} timeout={500} classNames="fade" unmountOnExit>
        <Box
          bgcolor="#eee"
          pt={2}
          pb={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography
            align="center"
            variant="h3"
            color="#222"
            id="about"
            gutterBottom
            pt={1}
          >
            About Me
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection={{ xs: "column", md: "row" }}
          >
            <Avatar
              src="/pic.jpg"
              sx={{ height: 180, width: 180, mb: { xs: 3, md: 0 } }}
            />
            <Summary />
          </Box>
        </Box>
      </CSSTransition>
    </div>
  );
}
