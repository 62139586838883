export const typography = {
  button: {
    fontSize: "1.1em",
  },
  h1: {
    fontFamily: "Montserrat, sans-serif",
  },
  h3: {
    fontFamily: "Montserrat, sans-serif",
  },
  h5: {
    fontFamily: "Montserrat, sans-serif",
  },
  h6: {
    fontFamily: "Montserrat, sans-serif",
  },
};
