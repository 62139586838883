import Grid from "@mui/material/Grid";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

export default function Contact() {
  return (
    <Box py={2}>
      <Container maxWidth="lg">
        <Typography
          id="contact"
          align="center"
          variant="h3"
          color="#222"
          mt={1}
        >
          Contact
        </Typography>

        <Grid mt={1} container xs={12} mb={2}>
          <Grid container justifyContent="center" xs={4}>
            <Link href="https://www.linkedin.com/in/corriestoddard">
              <IconButton>
                <LinkedInIcon fontSize="large" color="primary" />
              </IconButton>
            </Link>
          </Grid>
          <Grid container xs={4} justifyContent="center">
            <Link href="https://github.com/cocomittens">
              <IconButton>
                <GitHubIcon fontSize="large" color="primary" />
              </IconButton>
            </Link>
          </Grid>
          <Grid container xs={4} justifyContent="center">
            <Link href="mailto:corriestoddard@gmail.com">
              <IconButton>
                <EmailIcon fontSize="large" color="primary" />
              </IconButton>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
