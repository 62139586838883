import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";

export default function Badge(props) {
  return (
    <Chip
      color={"primary"}
      label={<Typography variant="button">{props.name}</Typography>}
      sx={{ my: 0.5 }}
    />
  );
}
