import React from "react";
import {
  Typography,
  Container,
  Box,
  Card,
  CardContent,
  CardActions,
  Grid,
  Avatar,
  useTheme,
} from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import { CSSTransition } from "react-transition-group";
import { useInView } from "react-intersection-observer";
import Badge from "../Components/Badge"; // Ensure the path is correct
import "../Styles/experience.css"; // Import CSS file for animations

const experiences = [
  {
    date: "2019 - 2021",
    company: "Blackberry",
    title: "UI Engineer",
    summary:
      "Created and improved the UI of a revolutionary AI-based cybersecurity platform.",
    technologies: ["TypeScript", "React", "Redux", "SCSS", "Material UI"],
    iconColor: "secondary",
  },
  {
    date: "2021 - 2023",
    company: "Volley",
    title: "Software Engineer",
    summary:
      "Developed AI-powered voice enabled interactive entertainment, including 'Who Wants to Be a Millionaire,' 'Heads Up,' and 'The Price Is Right' on devices like Alexa.",
    technologies: ["TypeScript", "React", "DynamoDB", "SCSS", "AWS"],
    iconColor: "secondary",
  },
  {
    date: "2024",
    company: "Outset",
    title: "Software Engineer (Contract)",
    summary: "Worked on an AI-moderated research platform.",
    technologies: ["Typescript", "React", "WebRTC", "Next.js"],
    iconColor: "secondary",
  },
];

const AnimatedCard = ({ children }) => {
  const { ref, inView } = useInView({ triggerOnce: true });

  return (
    <div ref={ref}>
      <CSSTransition in={inView} timeout={500} classNames="fade" unmountOnExit>
        {children}
      </CSSTransition>
    </div>
  );
};

export default function About() {
  const theme = useTheme();

  return (
    <Box
      py={2}
      sx={{ backgroundColor: theme.palette.background.default, mb: 6 }}
    >
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          align="center"
          variant="h3"
          color={theme.palette.text.primary}
          id="portfolio"
          mt={1}
          mb={4}
        >
          Experience
        </Typography>

        <Grid container spacing={3} justifyContent="center">
          {experiences.map((exp, index) => (
            <Grid item xs={12} key={index}>
              <AnimatedCard>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    p: 2,
                    pt: 2,
                    pb: 0,
                    backgroundColor: "#eee",
                    boxShadow: 3,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      sx={{ bgcolor: theme.palette[exp.iconColor].main, mr: 2 }}
                    >
                      <WorkIcon />
                    </Avatar>
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        variant="subtitle1"
                        color={theme.palette.text.secondary}
                      >
                        {exp.date}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ color: theme.palette.text.primary }}
                      >
                        {exp.company}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ color: theme.palette.text.secondary }}
                      >
                        {exp.title}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      color={theme.palette.text.secondary}
                      sx={{ ml: 2, fontSize: "1rem", flex: 2 }}
                    >
                      {exp.summary}
                    </Typography>
                  </Box>
                  <CardContent sx={{ pt: 1, pb: 0 }}>
                    <CardActions
                      sx={{ flexWrap: "wrap", justifyContent: "flex-start" }}
                    >
                      {exp.technologies.map((tech, i) => (
                        <Badge name={tech} key={i} alternate={i % 2 === 0} />
                      ))}
                    </CardActions>
                  </CardContent>
                </Card>
              </AnimatedCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
