export const components = {
    MuiTab: {
        styleOverrides: {
            root: {
                color: "#fff"
            },
            selected: {
                color: "#3a86ff"
            }
        }
        
    }
}