export const palette = {
  primary: {
    main: "#9157ff",
  },
  secondary: {
    main: "#444",
  },
  error: {
    main: "#ff006e",
  },
  warning: {
    main: "#ffbe0b",
  },
};
